(function(window) { var svs = window.svs || {}; svs.modules = svs.modules || []; if(svs.modules.indexOf('/components/lb-utils/set-meta-tags/assets/set-meta-tags.js') >= 0) return;  svs.modules.push('/components/lb-utils/set-meta-tags/assets/set-meta-tags.js');
'use strict';

const metaDescSelectors = ['meta[name=description]', "meta[property='og:description']", "meta[name='twitter:description']"];
const setMetaContent = metaDescription => selector => {
  const el = document.querySelector(selector);
  if (!el) {
    return;
  }
  el.content = metaDescription;
};
const setHeaderImageAltText = logotypeAltText => {
  if (!logotypeAltText) {
    return;
  }
  const header = document.getElementById('top-content');
  const h1Image = (header === null || header === void 0 ? void 0 : header.querySelectorAll('.brand-brand')) || [];
  if (h1Image.length) {
    h1Image.forEach(productImage => {
      if (productImage) {
        productImage.setAttribute('alt', logotypeAltText);
      }
    });
  }
};
const setMetaTags = _ref => {
  let {
    docTitle = '',
    metaDesc = '',
    canonicalUrl = ''
  } = _ref;
  if (docTitle) {
    document.title = docTitle;
  }
  if (metaDesc) {
    metaDescSelectors.forEach(setMetaContent(metaDesc));
  }
  const canonical = document.querySelector('link[rel="canonical"]');
  if (canonical !== null && canonical !== void 0 && canonical.href) {
    canonical.href = canonicalUrl || window.location.href;
  }
};
setGlobal('svs.components.lbUtils.setMetaTags', {
  setMetaTags,
  setHeaderImageAltText
});

 })(window);